import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import LeftMenuPage from "./Pub/Component/LeftMenuPage/LeftMenuPage";
import ApiMain from "./ApiMain/ApiMain";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import LoginModal from "./User/Login/LoginModal";

function App() {
  useEffect(() => {
    document.title = '机器人后台管理';
  }, []);
  const [currentPage, setCurrentPage] = useState('home');
  function setPage(page) {
    setCurrentPage(page);
    window.location.href = '#'+page;
  }
  useEffect(() => {
    const path = window.location.hash; // 获取当前 URL 的路径部分
    if (path === '#offsitepurchaseapi') {
      setCurrentPage('offsitepurchaseapi');
    }else if (path === '#LoginModal') {
      setCurrentPage('LoginModal');
    }
    else if (path === '#logout') {
      setCurrentPage('logout');
    }
    else {
      setCurrentPage('AdminDashboard');
    }
  }, [window.location.hash]);
  const RenderPage = () => {
    if (currentPage === 'offsitepurchaseapi') {
      return <ApiMain />;
    } else if (currentPage === 'AdminDashboard') {
      return <AdminDashboard setCurrentPage={setPage}/>;
    }else if (currentPage === 'LoginModal') {
      return <LoginModal setCurrentPage={setPage}/>;
    }
    else if (currentPage === 'logout') {
      return <LoginModal setCurrentPage={setPage}/>;
    }
  }
  return(<RenderPage />);
}

export default App;
